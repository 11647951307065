<template>
    <div class='leftMenu'>
        <el-menu :default-active="defaultActive" class="el-menu-vertical-demo" @select="handleOpen" background-color="transparent" active-background-color="#f00" text-color="#fff" active-text-color="#1890ff">
            <template v-for="(it,inx) in roleList">
                <el-menu-item :index="it.id" v-if="it.show" :key="inx">
                    <i class="el-icon-menu"></i>
                    <span slot="title">{{it.title}}</span>
                </el-menu-item>
            </template>
        </el-menu>
    </div>
</template>
<script>
export default {
    name:'leftMenu',
    data(){
       return{
        defaultActive:'0',
        newroleList:[
          { usertype:1,show:true,title:'用户列表', id:'1',url:'/home'},
          { usertype:1,show:true,title:'用户相册', id:'3',url:'/photo'},
          { usertype:1,show:true,title:'家族文化', id:'4',url:'/culture'},
          { usertype:1,show:true,title:'新闻动态', id:'5',url:'/journalism'},
          { usertype:1,show:true,title:'商品列表', id:'6',url:'/shop'},
          { usertype:1,show:true,title:'商品分类', id:'7',url:'/shopClassify'},
          { usertype:1,show:true,title:'系统日志', id:'9',url:'/systemLog'},
          { usertype:2,show:true,title:'家谱管理', id:'11',url:'/genealogicallibrary'},
          { usertype:2,show:true,title:'家谱权限申请列表', id:'10',url:'/applyList'},
          { usertype:1,show:true,title:'族谱人员设置审核', id:'15',url:'/audiUsertList'},
          { usertype:1,show:false,title:'族谱人员设置审核', id:'11',url:'/reelNumber'},
          { usertype:1,show:false,title:'族谱人员设置审核', id:'11',url:'/genealogy'},
        ],
        roleList:[]
       }
    },
    watch:{
    '$route.path': {
        handler(toPath, fromPath){
          let n = toPath;
          if(!sessionStorage.zujiUserInfo){
            this.$router.push({ path: "/login" });
          }
          let usertype = JSON.parse(sessionStorage.zujiUserInfo).userType;
          this.roleList = usertype==1?this.newroleList:this.newroleList.filter(v=>{return v.usertype==2})
          let id =this.roleList.filter(v=>{return v.url == n});
          this.defaultActive = id.length>0?id[0].id:'0';
            if(toPath == '/login'){
              this.showCont = false;
            }else{
              this.showCont = true;
            }
            // console.log('上一个路由地址：' + toPath)
        },
        deep: true, // 深度监听
        immediate: true, // 第一次初始化渲染就可以监听到
      }
    },
    methods:{
        handleOpen(key, keyPath) {
            let url =this.roleList.filter(v=>{return v.id == key})[0].url;
            this.$router.push({path:url})
        },
    }
}
</script>
<style lang='scss' scoped>
.leftMenu{
    width: 100%;
    height: 100%;
    background-color:#304156;
}
</style>
<style scoped>
.leftMenu /deep/.is-active{
    background-color: #1f2d3d!important;    
}
.leftMenu /deep/.el-menu-item{
    height: 50px;
    line-height: 50px;
}
</style>